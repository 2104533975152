<template>
  <router-view />
</template>

<script>

import axios from '@/api/axios'; // 引入封装好的api
import Cookie from 'js-cookie';

const validUrl = '/sso/check/token';

export default {
  name: 'App',

  async mounted() {

    this.timer = setInterval(async () => {
      try {
        const validResponse = await axios.post(validUrl,{});
        if (validResponse.status !== 200){
          Cookie.remove('token');
          Cookie.remove('tokenIsValid');
          Cookie.remove('application');
          Cookie.remove('refreshToken');
          Cookie.remove('applicationList');
        }

        if (validResponse.data.is_login !== true){
          Cookie.remove('token');
          Cookie.remove('tokenIsValid');
          Cookie.remove('application');
          Cookie.remove('refreshToken');
          Cookie.remove('applicationList');
        }
      } catch (error) {
        Cookie.remove('token');
        Cookie.remove('tokenIsValid');
        Cookie.remove('application');
        Cookie.remove('refreshToken');
        Cookie.remove('applicationList');
      }
    }, 60000);
  },

  unmounted() {
    clearInterval(this.timer)
  },
  components: {
  }
}
</script>

<style>
</style>
