<template>
  <div class="common-layout">
    <el-container id = "main" style="height: 100%">
      <el-header class="header">
        Header
        <el-dropdown class="login" v-if="!tokenIsValid" >
          <span class="login-btn"> 登录 </span>
          <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handleSSOLoginClick(item,index)" v-for="(item, index) in ssoList" :key="index">{{ item }}</el-dropdown-item>
              </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown class="selectApplication" v-if="tokenIsValid === 'true'" >
          <span class="login-btn"> 选择应用 </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleApplicationSelectClick(item,index)" v-for="(item, index) in applicationList" :key="index">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-header>
      <el-container class="all">
        <el-aside width="300px" class="tree-container">
            <el-tree class="custom-tree" ref="tree" :data="tree" node-key="id" @node-click="handleNodeClick" :default-expanded-keys="treeExpandedKeys"></el-tree>
        </el-aside>
        <div class="resize" >⋮</div>
        <el-main style="padding: 0;" class="right">
          <div align="left" style="position: relative" v-if="markdownPath">
            <div class="view-div2">
              <el-button class="edit-btn" v-if="tokenIsValid === 'true'" @click="handleEditClick">编辑</el-button>
              <MdPreview v-model="markdown"></MdPreview>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-footer class="footer">Footer</el-footer>
    </el-container>
  </div>

</template>

<script>
import router from '@/router';
import 'md-editor-v3/lib/style.css';
import axios from '@/api/axios'; // 引入封装好的api
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { ref } from "vue";
import Cookie from 'js-cookie';
import {TokenExpireTime} from "@/common/const";
let tokenIsValid = ref();
let applicationList = ref();

export default {

  setup(){
     tokenIsValid = Cookie.get('tokenIsValid');
     let resp = Cookie.get('applicationList')
     if (resp){
       applicationList = JSON.parse(resp);
     }

     return {tokenIsValid,applicationList}
  },

  components: {
    MdPreview
  },

  async mounted() {

    dragControllerLR()

    try {
      let resp = await axios.get("/sso/query/list");
      if (resp.data.list){
        this.ssoList = resp.data.list;
      }



      const navData = await axios.post("/md/query/nav",{});
      if (navData){
        this.tree = navData.data;
      }

      if (this.markdownPath) {
        let path = this.markdownPath;
        if (path.startsWith("/")){
          path = path.substring(1);
        }
        let id = FindPath(navData.data,path);

        this.treeExpandedKeys = [id];
        this.$refs.tree.setCurrentKey(id) // 默认选中节点第一个
        axios.post("/md/query/markdown",{
          "path": this.markdownPath,
        }).then(response => {
          this.markdown = response.data.data;
        }).catch(error => {
          console.error('请求失败:', error);
          this.markdown = ''
        });
      }
    }catch(err){
      console.log(err);
    }
  },

  unmounted() {
    clearInterval(this.timer)
  },

  data() {
    return {
      treeExpandedKeys:[],
      tree:[],
      markdown: '',
      clickNode:null,
      ssoList:[],
    };
  },
  methods: {
    handleNodeClick(data) {
      this.clickNode = data;
      if (data.path) {
        axios.post("/md/query/markdown",{
          "path": "/"+data.path,
        }).then(response => {
          this.markdown = response.data.data;
        }).catch(error => {
          console.error('请求失败:', error);
          this.markdown = ''
        });
      }
      
      if (data.path){
        router.push('/views/'+data.path);
      }
    },

    // 定义点击事件的处理函数
    handleEditClick() {
      let path = this.$route.params.path;
      if (path.startsWith("/")){
          path = path.substring(1);
      }
      router.push({
        path:'/edit/'+this.$route.params.path
      });
    },

    async handleSSOLoginClick(item,index) {
      if (item === "Login With Gitlab"){
        let ssoInfo = {};
        const response = await axios.get("/sso/query/gitlab");
        ssoInfo = response.data;
        window.location.href = `${ssoInfo.protocol}://${ssoInfo.domain}/oauth/authorize?client_id=${ssoInfo.clientId}&redirect_uri=${ssoInfo.redirect}&response_type=code&scope=${ssoInfo.scope}`;
      }
    },

    async handleApplicationSelectClick(item,index) {
      if (item === "logout"){
        Cookie.remove('token');
        Cookie.remove('tokenIsValid');
        Cookie.remove('application');
        Cookie.remove('applicationList');
        Cookie.remove('refreshToken');
        await router.push('/');
        location.reload();
        return
      }

     let resp = await axios.post("/application/select/repo",{
        "application":item,
      })

      if (resp.status === 200){
        Cookie.set('application',item,{expires: TokenExpireTime});

        await router.push('/');
        location.reload();
      }
    }
  },
  computed:{
    markdownPath:{
      get(){
        return this.$route.params.path === undefined ? '':`/${this.$route.params.path}`;
      },
      set(){}
    },
  }
};

// 左右拖动事件
function dragControllerLR() {
  let resize = document.getElementsByClassName("resize");
  let left = document.getElementsByClassName("el-aside");
  let right = document.getElementsByClassName("right");
  let box = document.getElementsByClassName("all");
  for (let i = 0; i < resize.length; i++) {
    // 鼠标按下事件
      resize[i].onmousedown = function (e) {
      let startX = e.clientX;
      resize[i].left = resize[i].offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = function (e) {
        let endX = e.clientX;
        let moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        let maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        // if (moveLen < 300) moveLen = 300; // 左边区域的最小宽度为50px
        if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

        resize[i].style.left = moveLen; // 设置左侧区域的宽度

        for (let j = 0; j < left.length; j++) {
          left[j].style.width = moveLen + "px";
          right[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
        }
      };
      // 鼠标松开事件
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    };
  }
}

function FindPath(arr,path){
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children === null || arr[i].children.length === 0){
      if (path === arr[i].path){
        return arr[i].id;
      }
    } else if (arr[i].children !== null && arr[i].children.length > 0 ) {
      let id = FindPath(arr[i].children,path);
      if (id !== -1){
        return id;
      }
    }
  }
  return -1;
}

</script>


<style scoped>
.common-layout{
  height: 100%;
  bottom: 0;
}

.header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 40px;
  height: 5%;
}

.footer{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  bottom: 0;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
}


.tree-container {
  height: 100%;
  border-right: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */
  overflow-y: auto;
  overflow-x: auto;
  background-color: #ffffff;
}


.custom-tree {
  height: 100%;
  min-width: 100%;
  display: inline-block !important;
}
/* 修改树节点的字体大小 */
.custom-tree .el-tree-node__content {
  height: 50px; /* 设置为你想要的高度 */
  font-size: 25px;
  background-color: #ffffff;
  line-height: 40px;
}

/* 修改树节点选中时的背景颜色 */
.custom-tree .el-tree-node.is-current > .el-tree-node__content {
  background-color: #409eff;
}

/* 修改树节点悬停时的背景颜色 */
.custom-tree .el-tree-node__content:hover {
  background-color: #e5e5e5;
}



.el-aside::-webkit-scrollbar {
  display: none;
}

.menu li {
  cursor: pointer;
  width: 120px;
  height: 50px;
  list-style-type:none;
  line-height: 50px;
}
.menu li:hover {
  background-color: #eee;
}

.right {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 30px;
}

.view-div2{
  top:0;
  position: absolute;
  width: 100%;
}

.edit-btn{
  background-color: #ffffff;
  position: sticky;
  left: 92%;
  top: 1px;
  height: 30px;
}

.login{
  position: absolute;
  right: 20px;
  top: 12px;
  background-color: transparent;
}

.selectApplication{
  position: absolute;
  right: 20px;
  top: 12px;
  background-color: transparent;
}

.login-btn{
  background-color: transparent;
}
.login:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}

.selectApplication:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}
</style>


