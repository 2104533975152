<template></template>
<script >
import axios from '@/api/axios'; // 引入封装好的api
import router from '@/router';
import Cookie from 'js-cookie';
import {TokenExpireTime} from "@/common/const";

export default {

  async mounted() {


    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {

      const tokenUrl = '/sso/query/token';

      try {
        const tokenResponse = await axios.post(tokenUrl,  {code: code} );
        const token = tokenResponse.data.token;
        const refreshToken = tokenResponse.data.refreshToken

        Cookie.set('token', token, {expires: TokenExpireTime});
        Cookie.set('refreshToken', refreshToken, {expires: TokenExpireTime});
        Cookie.set('tokenIsValid', 'true', {expires: TokenExpireTime});

        let resp = await axios.get("/application/query/list")
        if (resp.data.list){
          resp.data.list.push("logout");
          Cookie.set('applicationList', JSON.stringify(resp.data.list),{expires: TokenExpireTime});
        }

        await router.push('/')
      } catch (error) {
        Cookie.remove('token');
        Cookie.remove('tokenIsValid');
        Cookie.remove('application');
        Cookie.remove('applicationList');
        Cookie.remove('refreshToken');
        console.error('Authentication failed:', error);
        await router.push('/')
      }
    } else {
        Cookie.remove('token');
        Cookie.remove('tokenIsValid');
        Cookie.remove('application');
        Cookie.remove('refreshToken');
        Cookie.remove('applicationList');
        console.error('No authorization code provided');
        await router.push('/')
    }
  },

  unmounted() {

  }
}

</script>

<style scoped>

</style>