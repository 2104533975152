// api.js
import router from '@/router';
import axios from 'axios';
import Cookie from 'js-cookie';
import {TokenExpireTime} from "@/common/const";
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // API的base_url
    timeout: 60000 // 请求超时时间
});


// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这里可以做一些请求前的操作，比如设置token
        const token = Cookie.get("token");
        if (token) {
            config.headers['Token'] = 'Bearer ' + token;
        }

        const refreshToken = Cookie.get("refreshToken");
        if (refreshToken) {
            config.headers['Refresh-Token'] = refreshToken;
        }

        const application = Cookie.get("application");
        if (application ){
            config.headers['Application'] = application;
        }

        return config;
    },
    error => {
        // 请求错误处理
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (response.status === 401) {
            Cookie.remove('token');
            Cookie.remove('tokenIsValid');
            Cookie.remove('application');
            Cookie.remove('applicationList');
            Cookie.remove('refreshToken');
            router.push("/")
        } else if (response.status !== 200) {
            return Promise.reject(new Error(response || 'Error'));
        } else {
            const newRefreshToken = response.headers["refresh-token"]
            const newToken = response.headers["token"]
            const token = Cookie.get("token");

            if (newToken) {
                if (token && token !== newToken) {
                    // 获取当前时间
                    const now = new Date();
                    // 将当前时间格式化为字符串
                    const currentTime = now.toLocaleString();
                    // 打印当前时间
                    console.log(currentTime + "更新token")

                    Cookie.set('token', newToken, {expires: TokenExpireTime});
                    Cookie.set('refreshToken', newRefreshToken, {expires: TokenExpireTime});
                    Cookie.set('tokenIsValid', 'true', {expires: TokenExpireTime});
                }
            }
            return response;
        }
    },
    error => {
        if (error.response && error.response.status === 401) {
            Cookie.remove('token');
            Cookie.remove('tokenIsValid');
            Cookie.remove('application');
            Cookie.remove('applicationList');
            Cookie.remove('refreshToken');
            router.push("/")
            return
        }
        // 响应错误处理
        console.error('Response Error:', error);
        return Promise.reject(error);
    }
);

export default service;